import { HOURS_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import HoursContent from './HoursContent';

export default {
  key: HOURS_PAGE_KEY,
  path: '/hours',
  elId: DOM_ID,
  className: 'hours-page',
  component: GenericItemPage,
  childComponent: HoursContent,
};
