import { BRAND_PAGE_KEY } from 'src/pages/pagesKeys';

import GenericItemPage, { DOM_ID } from 'src/pages/generic-item-page/GenericItemPage';
import BrandContent from './BrandContent';

export default {
  key: BRAND_PAGE_KEY,
  path: '/brand',
  elId: DOM_ID,
  className: 'brand-page',
  component: GenericItemPage,
  childComponent: BrandContent,
  relatedDataToFetch: ['places'],
};
