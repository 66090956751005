import React from 'react';
import { isIphoneX } from 'src/core/util/browser';

import './IphonexFlexMargin.scss';

/**
 * This component adds a blank space on Iphone X at the bottom of the screen
 *
 * This requires that parent element has style:
 *  - display: flex
 *  - flex-direction: column
 *
 * e.g home, list pages
 */

function IphonexFlexMargin() {
  return global.isCordovaContext && isIphoneX() ? <div className="iphonex-flex-margin" /> : null;
}

export default IphonexFlexMargin;
