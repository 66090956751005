import { DATA_TYPE_BRANDS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES } from './dataConfig';
import { SEARCH_PAGE_KEY } from 'src/pages/pagesKeys';
import { SEARCH_TYPES } from 'src/core/search/Search';

// Perform search when user has typed at least n characters
export const SEARCH_MIN_CHARS = 2;

//export const SEARCH_HIDE_LIST_IF_TOTAL_ABOVE = 10;

/**
 * Data types to look through to find POI on map or itinerary start/destination
 * @type {Array}
 */
export const DATATYPES_WITH_PLACES = [DATA_TYPE_BRANDS, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICES];
export function getSearchType(pageKey, value) {
  if (pageKey !== SEARCH_PAGE_KEY) {
    if (value.length === 1) {
      return SEARCH_TYPES.STARTS_WITH;
    }
    if (value.length === 2) {
      return SEARCH_TYPES.WORD_STARTS_WITH;
    }
  }
}

// Common 'title'
const getTitleAttribute = item => item.title;

/**
 * Expose:
 *  - data types
 *  - and functions returning the value
 * on which search is performed
 */
const conf = {
  [DATA_TYPE_BRANDS]: getTitleAttribute,
  [DATA_TYPE_BRAND_CATEGORIES]: getTitleAttribute,
  [DATA_TYPE_RESTAURANTS]: getTitleAttribute,
  [DATA_TYPE_MONUMENTS]: getTitleAttribute,
  [DATA_TYPE_RESTAURANT_CATEGORIES]: getTitleAttribute,
  [DATA_TYPE_SERVICES]: getTitleAttribute,
  [DATA_TYPE_SERVICE_CATEGORIES]: getTitleAttribute
};
export const get = profile => conf;