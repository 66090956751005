import { DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_MONUMENTS, DATA_TYPE_HAPPENINGS, DATA_TYPE_SERVICES } from './dataConfig';
import { DEFAULT_PROFILE, ENV } from './config';
import { createItem, createItemLang, createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isMobile } from 'src/core/util/browser';
import { fetchHappeningsDataFromDB } from 'src/core/happenings/happenings';
import { getOriginalIdFromDb, getIdFromDb } from 'src/core/services/services';
import { FAVORITES_PAGE_KEY, HOURS_PAGE_KEY, INBOX_PAGE_KEY, HAPPENINGS_PAGE_KEY, LIST_PAGE_KEY, MOBIGEO_PAGE_KEY, SEARCH_PAGE_KEY, SERVICE_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';
import config from './config';
const LOG_PREF = '[menuConfig] ';

// documentation: https://material-ui.com/api/swipeable-drawer/#props
export const MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0,
  // default 20
  disableSwipeToOpen: false
};

// see https://github.com/markusenglund/react-switch#api
export const LOCATION_BUTTON_PROPS = {
  onColor: '#E7E7E7',
  // background active
  background: "#76777A",
  // background inactive
  onHandleColor: '#00D072',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 25,
  width: 45
};
export const PMR_BUTTON_PROPS = {
  onColor: '#E7E7E7',
  background: "#76777A",
  onHandleColor: '#00D072',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 25,
  width: 45
};
const ICON_SIZE = '80%';

/**
 * Generate an item routing to Exhibitors page
 */
const getItemRouteBrands = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/brands.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.brands,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_BRAND_CATEGORIES
  }]
});

/**
 * Generate an item routing to Restaurants page
 */
const getItemRouteRestaurants = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/restaurants.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.restaurants,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_RESTAURANT_CATEGORIES
  }]
});

/**
 * Generate an item routing to Monuments page
 */
const getItemRouteMonuments = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/monuments.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.monuments,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_MONUMENTS
  }]
});

/**
 * Generate an item routing to Happenings page
 */
const getItemRouteHappenings = configHappenings => createItemRoute(
// style:
{
  container: {
    className: 'happening-menu-item'
  },
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl(configHappenings.icone_file_name) + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
configHappenings.title,
// page key:
HAPPENINGS_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_HAPPENINGS
  }]
});

/**
 * Generate an item routing to Services page
 */
const getItemRouteServices = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/services.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.services,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_SERVICE_CATEGORIES
  }]
});

/**
 * Generate a menu entry to open TOU link
 */
/**
 * Generate an item routing to Services page
 */
const getItemRouteServicesPrintania = (labels, id, originalId) => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/printania.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.printania,
// page key:
SERVICE_PAGE_KEY,
// page props:
{
  dataType: DATA_TYPE_SERVICES,
  id,
  originalId
});

/**
 * Generate a menu entry to open TOU link
 */
const getActionTOU = (labels, actions) => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/cgu.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.cgu,
// function to execute:
() => {
  if (openUrl(config.TOU_LINK[labels.id], null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
    actions.linkClicked(config.TOU_LINK[labels.id]);
  }
});

/**
 * Generate an item routing to Services page
 */
const getItemRouteHours = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/infos.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.hours,
// page key:
HOURS_PAGE_KEY);

/**
 * Generate an item routing to Map page
 */
const getItemRouteMap = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/location.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.map,
// page key:
MOBIGEO_PAGE_KEY);

/**
 * Generate an item routing to Search page
 */
const getItemRouteSearch = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/search.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.search.title,
// page key:
SEARCH_PAGE_KEY);

/**
 * Generate an item routing to Favorites page
 */
const getItemRouteFavorites = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/favourites.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.favorites.title,
// page key:
FAVORITES_PAGE_KEY);

/**
 * Generate an item routing to Inbox page
 */
const getItemRouteInbox = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/inbox.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.inbox,
// page key:
INBOX_PAGE_KEY);
const getItemLang = (labels, actions) => createItem({
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/langues.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
}, labels.menu.language, actions.showChooseLangDialog);
const getItemToggleLocation = labels => createItemToggleLocation(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(./icons-svg/position-new.svg)',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.location);
export const getItemTogglePMR = labels => createItemTogglePMR(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/pmr.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.pmr);
const getItemLogin = (labels, actions) => createItem(null, labels.menu.login, actions.showLoginDialog);
const getItemProfile = (labels, actions) => createItem({
  icon: {
    className: 'fa fa-user',
    style: {
      fontSize: '1.5em',
      lineHeight: '1.3em',
      color: 'grey'
    }
  }
}, labels.menu.profile, actions.showProfileDialog);
const getAdMenuButton = (conf, labels, actions) => !conf ? null : createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl(conf['img_' + labels.id]) + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
conf['name_' + labels.id],
// action:
() => {
  let url = conf['link_' + labels.id];
  if (openUrl(url)) {
    actions.linkClicked(url);
  }
});

/**
 * Menu configuration
 */
const getConfig = (actions, labels, adConfig, twoColumns, happeningsConfig) => {
  const idPrintania = getIdFromDb && getIdFromDb(DATA_TYPE_SERVICE_CATEGORIES, 'title', labels.menu.printania);
  const originalIdPrintania = getOriginalIdFromDb && getOriginalIdFromDb(DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES, 'title', labels.menu.printania, 'original_id');
  let conf = {
    default: [happeningsConfig ? getItemRouteHappenings(happeningsConfig) : null, getItemRouteBrands(labels), getItemRouteRestaurants(labels), getItemRouteServices(labels), getItemRouteMonuments(labels), getItemRouteHours(labels), idPrintania && originalIdPrintania && getItemRouteServicesPrintania(labels, idPrintania, originalIdPrintania), getItemRouteMap(labels),
    // Generic:
    getItemRouteSearch(labels), getItemRouteFavorites(labels), getItemRouteInbox(labels), getItemLang(labels, actions),
    //createItemToggleLocation(),
    getItemToggleLocation(labels), getItemTogglePMR(labels), getActionTOU(labels, actions)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }

  // Filter any empty entry
  Object.keys(conf).forEach(function (profile) {
    conf[profile] = conf[profile].filter(menuItemConfig => menuItemConfig);
  });
  return conf;
};

// const replaceRouteFolderPublic = (route) => {
//   if (route.match(/^public/g)) return process.env.PUBLIC_URL + route.replace('public', '');
//   return route;
// };

/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */
export const get = function () {
  let profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  let actions = arguments.length > 2 ? arguments[2] : undefined;
  let labels = arguments.length > 3 ? arguments[3] : undefined;
  let adConfig = arguments.length > 4 ? arguments[4] : undefined;
  let twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  let conf = getConfig(actions, labels, adConfig, twoColumns, fetchHappeningsDataFromDB());
  // Return config depending on page
  if (conf) {
    return conf[page] || conf.default;
  }
  // else undefined is returned
};