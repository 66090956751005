import { debounce } from 'lodash-custom';

import config from 'app-customs/config/config';
import {
  DATA_TYPE_PARTICIPANTS,
  getParticipantExhibitorGaiaId,
} from 'app-customs/config/dataConfig';
import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import * as ParticipantsWsTaiga from 'src/core/webservices/taiga/ParticipantsWS';
import { parseDate } from 'src/core/webservices/taiga/helpers';
import { TAIGA } from 'src/core/webservices/WsProviders';
import { isSessionValid } from 'src/core/login/LoginService';
import * as UserDataService from 'src/core/user-data/UserDataService';
import * as Db from 'src/core/data-and-assets/Db';
import * as Query from 'src/core/query/Query';
import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';

import showGenericWsErrorNotification from 'src/core/webservices/showGenericWsErrorNotification';

const LOG_PREF = '[ParticipantsService] ';

export function isSameUser(participant) {
  if (participant) {
    const userData = UserDataService.getUserData();
    if (userData) {
      return userData.id === participant.id;
    }
  }
  return false;
}

export function hasParticipantBeenContacted(participant) {
  if (participant) {
    const userData = UserDataService.getUserData();
    if (userData && Array.isArray(userData.contactedParticipants)) {
      const contacted = userData.contactedParticipants.find(
        (contact) => contact.id === participant.id
      );
      return !!contacted;
    }
  }
  return false;
}

let lastFetchDate;

function hasDataExpired() {
  if (!lastFetchDate) {
    return true;
  }
  const delaySinceLastFetch = lastFetchDate && new Date().getTime() - lastFetchDate;
  return delaySinceLastFetch > config.NETWORKING.ALL_PARTICIPANTS_DATA_EXPIRE_DELAY;
}

export const getParticipantsCacheMaxLength = () => config.SEARCH_TAIGA.WS_PARAMS.take * 2;

/**
 * Do no use externally, it is exported only for test purpose
 * @param  {array} freshData
 */
export function storeData(freshData) {
  let participantsToSaveLocally;

  if (config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL') {
    participantsToSaveLocally = freshData;
  } else if (config.NETWORKING.PARTICIPANTS_DATA_MODE === 'PARTIAL') {
    // See SAN-40 - TL;DR It is decided that we keep a cache limited to: SEARCH_TAIGA.WS_PARAMS.take x 2
    let savedData = Db.getSortedAndTransformedData()[DATA_TYPE_PARTICIPANTS] || [];

    // 1 - Remove from currently saved participants the items we have in the fresh participants data
    savedData = savedData.filter((participant) => {
      const existingFoundInFresh = freshData.find((fp) => fp.id === participant.id);
      return !existingFoundInFresh;
    });

    // 2 - Append the fresh data
    savedData = savedData.concat(freshData);

    // 3 - Limit length
    const maxAmount = getParticipantsCacheMaxLength();
    if (savedData.length > maxAmount) {
      savedData.splice(0, savedData.length - maxAmount);
    }
    participantsToSaveLocally = savedData;
  }

  // Add participants data to the Db module
  // which allows generic app behaviour for search, filter, favorites, fiches,
  // and faster display (local data being instantly used (if any), until WS responds)
  Db.appendOrUpdateSortedAndTransformedData(participantsToSaveLocally, DATA_TYPE_PARTICIPANTS);
}

export function clearData() {
  // Remove participants data from local Db module
  lastFetchDate = null;
  Db.clearData(DATA_TYPE_PARTICIPANTS);
  getBindedActions().dataAssetsUpdated([DATA_TYPE_PARTICIPANTS]);
}

/**
 * Fetch participants from web service
 *
 * debounced because it can be called by multiple sources at boot time, e.g:
 *  - after background login success (see participantsMiddleware)
 *  - user landing on participants list
 *
 * @param  {function} cb
 */
export const getParticipants = debounce((cb) => {
  const hasCallback = typeof cb === 'function';

  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    if (hasCallback) {
      cb(null, []);
    }
    return;
  }

  if (!hasDataExpired()) {
    if (hasCallback) {
      const participants = Query.getAll(DATA_TYPE_PARTICIPANTS) || [];
      cb(null, participants);
    }
    return;
  }

  switch (config.NETWORKING.PROVIDER) {
    case TAIGA:
      ParticipantsWsTaiga.get(null, (error, data) => {
        data = commonParticipantWsHandler(error, data, cb);

        // Special case for whole data fetch
        if (data) {
          lastFetchDate = new Date().getTime();
          if (!hasCallback) {
            getBindedActions().dataAssetsUpdated([DATA_TYPE_PARTICIPANTS]);
          }
        }
      });
      break;

    default:
      console.error(`${LOG_PREF}Unexpected web service provider: ${config.NETWORKING.PROVIDER}`);
  }
}, 400);

/**
 * Trigger participant contact by calling web service
 * @param  {number|string} participantId
 * @param  {Function} cb (callback)
 */
export function contactParticipant(participantId, cb) {
  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    cb({ error: WS_ERRORS.AUTH });
  }

  function next(error) {
    getBindedActions().contactRequestPerformed({
      id: participantId,
      dataType: DATA_TYPE_PARTICIPANTS,
      ws: config.NETWORKING.PROVIDER,
      error,
    });

    if (error === WS_ERRORS.AUTH) {
      getBindedActions().navigate(LOGIN_PAGE_KEY);
    }
    if (typeof cb === 'function') {
      cb(error);
    }
    UserDataService.refreshUserDataFromAPI();
  }

  switch (config.NETWORKING.PROVIDER) {
    case TAIGA:
      ParticipantsWsTaiga.contactParticipant(participantId, next);
      break;

    default:
      console.error(`${LOG_PREF}Unexpected web service provider: ${config.NETWORKING.PROVIDER}`);
  }
}

/**
 * @param  {object}   fields
 * @param  {Function} cb
 */
export function search(fields, cb) {
  if (typeof cb !== 'function') {
    console.error(`${LOG_PREF}search: Missing callback`);
    return;
  }

  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    cb({ error: WS_ERRORS.AUTH });
  }

  ParticipantsWsTaiga.get(fields, (error, data) => {
    commonParticipantWsHandler(error, data, cb);
  });
}

/**
 * @param  {array} ids
 * @param  {function} cb (callback)
 */
export function getParticipantsByIds(ids, cb) {
  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    cb({ error: WS_ERRORS.AUTH });
  }

  if (Array.isArray(ids) !== true || ids.length === 0) {
    cb({});
    return;
  }

  search({ ids }, cb);

  // Until the webservice responds, use local data
  const data = [];
  ids.forEach((id) => {
    const participant = Query.get(id, DATA_TYPE_PARTICIPANTS);
    if (participant) {
      data.push(participant);
    }
  });
  cb({
    error: null,
    data: Db.sortItems(data, DATA_TYPE_PARTICIPANTS),
  });
}

export function getParticipantsRelatedToAnExhibitor(exhibitorGaiaId, cb) {
  // There is no TAIGA server-side log out. So as long as the cookie is valid, the app
  // can still execute requests on the backend, so it is needed to prevent WS calls
  if (isSessionValid() !== true) {
    cb({ error: WS_ERRORS.AUTH });
  }

  if (!exhibitorGaiaId) {
    cb({});
    return;
  }
  search({ exhibitorId: exhibitorGaiaId }, cb);
}

function commonParticipantWsHandler(error, data, cb) {
  if (error) {
    getBindedActions().showNotification({
      message: getLabels().networking.participantsFetchError,
      level: 'warning',
    });
    // showGenericWsErrorNotification(error);
  }

  if (Array.isArray(data)) {
    storeData(data);
    data = Db.sortItems(data, DATA_TYPE_PARTICIPANTS);
  } else {
    data = [];
  }

  cb({
    error,
    data,
  });

  return data;
}
