//import { HOME_PAGE_KEY } from 'src/pages/pagesKeys';

function blackText() {
  global.StatusBar.styleDefault();
}
function whiteText() {
  global.StatusBar.styleLightContent();
}
export default function configureStatusBar(pageKey) {
  // doc: https://github.com/apache/cordova-plugin-statusbar/

  global.StatusBar.overlaysWebView(false);
  switch (pageKey) {
    // case HOME_PAGE_KEY:
    //     global.StatusBar.styleLightContent();
    //     global.StatusBar.backgroundColorByHexString('#000000');
    //     break;

    default:
      blackText();
      global.StatusBar.backgroundColorByHexString('#ffffff');
  }
}