import { DATA_TYPE_INFOS, DATA_TYPE_PMRS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTS } from './dataConfig';
import { MOBIGEO_PAGE_KEY } from 'src/pages/pagesKeys';
import { WEBAPP_MAP } from './profiles';
export function desactivateOnClickOnLi(pageKey, profile) {
  const blackListPageKey = [];
  const blackListProfile = [];
  if (pageKey && blackListPageKey.includes(pageKey) && profile && blackListProfile.includes(profile)) {
    return true;
  }
  return false;
}
export function isLogoFullWidth(pageKey) {
  switch (pageKey) {
    default:
      return true;
  }
}
export const EVENT_PAGE_SPEAKERS_AS_ACCORDION = false;
export const SINGLE_ITEM_DATATYPES = [DATA_TYPE_INFOS, DATA_TYPE_PMRS];
export const DEFAULT_IMAGE = {
  [DATA_TYPE_PARTICIPANTS]: 'files/project/misc/default-speaker-round.png',
  [DATA_TYPE_CONTACTS]: 'files/project/misc/default-speaker-round.png'
};
export const CATEGORIES_CLASSIFICATIONS = {
  CATEGORY_SYSTEM: 'CATSYS',
  TOP_CATS: 'TOPCATS'
};
// CATEGORY_SYSTEM || TOP_CATS
export const CATEGORIES_CLASSIFICATION = CATEGORIES_CLASSIFICATIONS.TOP_CATS;