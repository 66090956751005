import { LANG_CHANGED } from 'src/store/actionTypes';
import { init } from 'src/core/data-and-assets/DataAssetsUtil';
import { clear as clearPersistedData } from 'src/core/data-and-assets/DbPersistence';

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case LANG_CHANGED:
        // On lang change, data needs to be reloaded

        if (global.isCordovaContext !== true) {
          init();
        } else {
          clearPersistedData((err) => {
            if (err) {
              // ignored
            }
            init();
          });
        }
        break;

      default:
    }
    return result;
  };
