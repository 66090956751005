import React from 'react';
import PropTypes from 'prop-types';

import './NoResult.css';

function NoResult(props) {
  return <div className="no-result">{props.customLabel || props.labels.common.noResult}</div>;
}

NoResult.defaultTypes = {
  customLabel: null,
};

NoResult.propTypes = {
  customLabel: PropTypes.string,
  labels: PropTypes.object.isRequired,
};

export default NoResult;
