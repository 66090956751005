import React from 'react';
import PropTypes from 'prop-types';
import { isAndroid } from 'src/core/util/browser';
import showPdf from 'src/core/pdf/pdfViewerAndroid';
import { getBindedActions } from 'src/store/bindedActions';

import { openUrl, removeLinkProtocol } from 'src/core/util/JsTools';

function Url({
  label,
  href,
  target,
  openInInAppBrowser,
  onClick,
  beforeAction,
  disableClick = false,
  className,
  customClassName,
  children,
  callback,
  noCache,
  ...props
}) {
  if (typeof onClick === 'function') {
    console.error("Do not override 'onClick' property, use 'callback' prop instead", props);
  }
  if (typeof callback !== 'function') {
    callback = global.noop;
  }

  function openUrlCallback(result) {
    if (result === true) {
      callback(href);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (disableClick && href) {
      openUrlCallback(true);
    } else {
      let goAhead = true;

      if (beforeAction && typeof beforeAction === 'function') {
        goAhead = beforeAction();
      }

      if (href && goAhead) {
        const url = noCache ? `${href}?rnd=${new Date().getTime()}` : href;
        if (href.includes('.pdf') && isAndroid()) {
          getBindedActions().linkClicked(url);
          showPdf(url);
        } else {
          openUrl(url, openUrlCallback, target, openInInAppBrowser);
        }
      }
    }
  };

  return (
    <a
      className={`link ${
        customClassName ||
        (className === 'custom-colored-link' ? 'custom-colored-link' : 'colored-link')
      }  ${!href ? 'empty-link' : ''} ${className || ''}`}
      onClick={handleClick}
      {...props}
    >
      {removeLinkProtocol(label) || children}
    </a>
  );
}

Url.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  openInInAppBrowser: PropTypes.bool,
  label: PropTypes.string, // not required as this component can wrap an image for instance
  className: PropTypes.string,
  callback: PropTypes.func,
};

export default Url;
