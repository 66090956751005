import React from 'react';
import PropTypes from 'prop-types';

import config from 'app-customs/config/config';

import Url from 'src/components/url/Url';

import './MobilespotButton.scss';

function MobilespotButton({ labels }) {
  return (
    <div className="mobilespot-button content-font" title={labels.promoMs}>
      <Url href={config.MOBILESPOT_WWW} label={labels.mobileSpotButton} />
    </div>
  );
}

MobilespotButton.propTypes = {
  labels: PropTypes.object.isRequired,
};

export default MobilespotButton;
