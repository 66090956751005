import { debounce } from 'lodash-custom';

import { NAVIGATE, NAVIGATE_BACK, NAVIGATE_TO_ITEMS, WINDOW_RESIZED } from 'src/store/actionTypes';

import { navigate } from 'src/store/actions';

import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';
import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';

import { _navigate } from 'src/core/navigation/Router';
import { back } from 'src/core/navigation/History';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { customizeStatusBar } from 'src/core/cordova/CordovaHelper';

const handleWindowResized = debounce(() => {
  TwoColumnsModeManager.autoEnable(true);
}, 1000);

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case NAVIGATE:
        _navigate(action.pageKey, action.options, action.transition, action.historyAction);

        // Update cordova app status bar style
        customizeStatusBar(action.pageKey);
        break;

      case NAVIGATE_BACK:
        back();
        break;

      case NAVIGATE_TO_ITEMS:
        if (!action.items) {
          return;
        }

        // Directly go to the single item
        if (action.items.length === 1) {
          dispatch(
            navigate(DATA_TYPE_TO_PAGE_KEY[action.dataType], {
              id: action.items[0].id,
            })
          );
        } else {
          // Display a list
          dispatch(
            navigate(LIST_PAGE_KEY, {
              inputs: [
                {
                  parentId: action.parentId,
                  parentType: action.parentDataType,
                  dataType: action.dataType,
                },
              ],
            })
          );
        }
        break;

      case WINDOW_RESIZED:
        // Detect when '2 columns mode' can be toggled
        handleWindowResized();
        break;

      default:
    }
    return result;
  };
