import config from 'app-customs/config/config';
import { notificationHandler } from 'src/pages/inbox/InboxService';
import events from 'src/core/events.js';

const LOG_PREF = '[CordovaPushWooshManager] ';

function isPushWooshEligible() {
  return global.isCordovaContext && config.appId && config.projectId;
}

/**
 * Perform device register
 */

function onNotificationReceived() {
  notificationHandler(document.currentNotif);
  document.currentNotif = null;
  events.unsubscribe(onNotificationReceived);
  document.removeEventListener('resume', onNotificationReceived);
}
export function init() {
  if (isPushWooshEligible()) {
    let pushwoosh;
    let notificationReceived = false;
    try {
      pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

      pushwoosh.onDeviceReady({
        appid: config.appId,
        projectid: config.projectId,
      });

      pushwoosh.registerDevice(
        (status) => {
          console.debug(`${LOG_PREF}Registered with push token: `, status.pushToken);
        },
        (error) => {
          console.error(`${LOG_PREF}Failed to register: `, error);
        }
      );
      pushwoosh.setMultiNotificationMode();
      console.info(`${LOG_PREF}initialized`);

      /*
      Handle notification from notification click (both background and killed app)
      */
      document.addEventListener('push-notification', (event) => {
        const notifs = event.notification;
        if (typeof notifs !== 'undefined') {
          // handle custom notification data
          if (notifs && !notificationReceived) {
            document.currentNotif = notifs;
            !notifs.foreground &&
              (notifs.ios
                ? notificationHandler(notifs)
                : document.addEventListener('resume', onNotificationReceived));
            events.subscribe('appReady', onNotificationReceived);
          } else {
            notificationReceived = false;
          }
        }
      });

      /*
      Handle notification when app is in foreground
      */
      document.addEventListener('push-receive', (event) => {
        const notifs = event.notification;
        if (typeof notifs !== 'undefined') {
          // handle custom notification data
          if (notifs && !notifs.onStart) {
            notificationHandler(notifs);
            notificationReceived = true;
          } else notificationReceived = false;
        }
      });
    } catch (e) {
      console.error(`${LOG_PREF}Failed to load PushWoosh cordova plugin`, e);
    }
  } else {
    console.debug('Skipped PushWoosh registration');
  }
}

export function setTag(key, value) {
  if (isPushWooshEligible()) {
    let pushwoosh;
    try {
      pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

      pushwoosh.setTags(
        { [key]: value },
        (status) => {
          console.info(`${LOG_PREF}setTags success`, status);
        },
        (error) => {
          console.info(`${LOG_PREF}setTags failed`, error);
        }
      );
    } catch (e) {
      console.error(`${LOG_PREF}Failed to load cordova plugin PushWoosh`, e);
    }
  }
}
