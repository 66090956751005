import { CATEGORIES_MAPPING } from 'app-customs/config/dataConfig';

import {
  FILTER_CATEGORY_TOGGLE,
  FILTER_RESET,
  FILTER_TOP_CAT_SELECTED,
  // HIDE_FILTER_DIALOG,
  LANG_CHANGED,
  SHOW_FILTER_DIALOG,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import { get, getCategoriesHierarchy, getParentCategoriesId } from 'src/core/query/Query';
import { getSortedAndTransformedData, sortItems } from 'src/core/data-and-assets/Db';

import { get as getLabels } from 'src/core/Lang';

import { simpleSort } from 'src/core/util/sortUtil';

import { langChanged, updateObject, windowResized } from 'src/store/reducers/commons';
import { isTopCatId, isChecked } from './FilterDialogHelpers';

function getDefaultState() {
  return {
    labels: getLabels(),
    selectedTopCatId: null,
    checkedCats: {},
  };
}

let lastDataType;
const _showFilterDialog = (state, action) => {
  let newState = {};
  if (action.dataType !== lastDataType) {
    // props reset only when data type changes
    newState = _filterReset(state);
  }
  lastDataType = action.dataType;

  newState.topCats = action.topCats;
  newState.dataType = action.dataType;
  newState.itemDataType = CATEGORIES_MAPPING[action.dataType];

  // Select first top cat on dialog show
  const defaultSelectedTopCat = action.topCats.length > 0 ? action.topCats[0].id : null;
  if (defaultSelectedTopCat !== null) {
    newState.selectedTopCatId = defaultSelectedTopCat;
    newState.content = getContent(defaultSelectedTopCat, action.dataType);
  }

  return updateObject(state, newState);
};

function getContent(catId, dataType) {
  const cat = getCategoriesHierarchy(catId, dataType);
  return sortItems(cat.cats, dataType);
}

function _filterTopCatSelected(state, action) {
  if (state.selectedTopCatId !== action.catId) {
    return updateObject(state, {
      selectedTopCatId: action.catId,
      content: getContent(action.catId, action.dataType),
      tabIndex: action.tabIndex,
    });
  }
  return state;
}

const MATCHING_TIME_CODE = 'Filter: find matching items';

export function _filterCategoryToggle(state, action) {
  const { catId, dataType, toRemove } = action;
  const itemDataType = CATEGORIES_MAPPING[dataType];

  const cat = getCategoriesHierarchy(catId, dataType);

  // Append the ids from the hierarchy of sub categories
  let catIds = [catId].concat(getSubCategoriesId(cat));

  // Iterate on parent categories
  if (cat.parent_id) {
    if (toRemove) {
      // Also uncheck parent categories
      catIds = catIds.concat(getParentCategoriesId(cat, dataType));
    } else {
      // Also check parent categories whose sub cats are all checked
      catIds = catIds.concat(getParentCategoriesIdWhoseSubCatsAreAllChecked(state, cat, dataType));
    }
  }

  /*
    {
        topCatId1: [ checked cats... ],
        topCatId2: [ checked cats... ],
    }
    */

  // Update the state property listing checked categories id
  const updCheckedCats = { ...state.checkedCats };

  const currentTopCatId = isTopCatId(state.topCats, catId) ? catId : state.selectedTopCatId;

  // Init array if needed
  if (!updCheckedCats[currentTopCatId]) {
    updCheckedCats[currentTopCatId] = [];
  }
  if (toRemove) {
    updCheckedCats[currentTopCatId] = updCheckedCats[currentTopCatId].filter(
      (catId) => catIds.indexOf(catId) === -1
    );
    if (updCheckedCats[currentTopCatId].length === 0) {
      delete updCheckedCats[currentTopCatId];
    }
  } else {
    updCheckedCats[currentTopCatId] = updCheckedCats[currentTopCatId].concat(catIds);
  }

  // Count results
  console.time(MATCHING_TIME_CODE);
  const matchingItemsId = getMatchingItemsId(updCheckedCats, itemDataType);
  console.timeEnd(MATCHING_TIME_CODE);

  return updateObject(state, {
    checkedCats: updCheckedCats,
    checkedCatsCount: Object.keys(updCheckedCats).reduce(
      (total, currentTopCatId) => total + updCheckedCats[currentTopCatId].length,
      0
    ),
    matchingItemsId,
  });
}

function getSubCategoriesId(cat) {
  let subCategories = [];

  if (Array.isArray(cat.cats)) {
    cat.cats.forEach((subCat) => {
      subCategories.push(subCat.id);
      subCategories = subCategories.concat(getSubCategoriesId(subCat));
    });
  }
  return subCategories;
}

/**
 * @param  {object} state
 * @param  {object} cat
 * @param  {string} dataType
 * @return {array}
 */
function getParentCategoriesIdWhoseSubCatsAreAllChecked(state, cat, dataType) {
  const parentCat = get(cat.parent_id, dataType);
  let ids = [];

  // Count unchecked sub categories
  const uncheckedSubCats = parentCat.lump?.cats.filter(
    (subCatId) =>
      subCatId !== parentCat.id &&
      subCatId !== cat.id && // ignore `cat` because state has not been updated yet
      !isChecked(state, subCatId)
  );
  if (uncheckedSubCats.length === 0) {
    ids.push(cat.parent_id);
    if (parentCat.parent_id) {
      // Also check parent's parent
      ids = ids.concat(getParentCategoriesIdWhoseSubCatsAreAllChecked(state, parentCat, dataType));
    }
  }
  return ids;
}

function doMatch(itemCats, checkedCats) {
  for (let i = 0; i < itemCats.length; i++) {
    if (checkedCats.indexOf(itemCats[i]) !== -1) {
      return true;
    }
  }
  return false;
}

const getMatchingItemsId = (cats, itemsDataType) => {
  const topCatIds = Object.keys(cats).sort((catIdA, catIdB) =>
    simpleSort(cats[catIdA].length, cats[catIdB].length)
  );

  return getSortedAndTransformedData()
    [itemsDataType].filter((item) => {
      let status = true;
      for (let i = 0; i < topCatIds.length && status; i++) {
        status = status && doMatch(cats[topCatIds[i]], item.lump?.parentCats);
      }
      return status;
    })
    .map((item) => item.id);
};

const _filterReset = (state) =>
  updateObject(state, {
    selectedTopCatId: null,
    checkedCats: {},
    checkedCatsCount: 0,
    content: null,
    matchingItemsId: null,
  });

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case SHOW_FILTER_DIALOG:
      return _showFilterDialog(state, action);
    // case HIDE_FILTER_DIALOG: return _hideFilterDialog(state, action);

    case FILTER_TOP_CAT_SELECTED:
      return _filterTopCatSelected(state, action);
    case FILTER_CATEGORY_TOGGLE:
      return _filterCategoryToggle(state, action);
    case FILTER_RESET:
      return _filterReset(state);
    case LANG_CHANGED:
      return langChanged(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);

    default:
      return state;
  }
};
