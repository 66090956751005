import { DATA_TYPE_HAPPENINGS } from 'app-customs/config/dataConfig';
import { get as getLabels } from 'src/core/Lang';

import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  FETCH_HAPPENINGS_FEED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  PROFILE_CHANGED,
  SET_FAVORITES_SYNCHRONIZATION_STATUS,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
  TOGGLE_FAVORITE,
  NOTE_DELETED,
  NOTE_SAVED,
  SEARCH_PERFORMED,
  NAVIGATE,
  KEYBOARD_TOGGLED,
  ITEM_FETCHED,
  ITEM_BEING_FETCHED,
  FETCH_FAVORITES,
  DATA_ASSETS_UPDATED,
  CLEAR_SEARCH_RESULTS,
  ALL_FAVORITES_DELETED,
  ACTIVATE_CONTRIBUTIONS_REFRESH,
  SET_SEARCH_FIELD_VISIBLE,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  togglePageAfterNavigation,
  profileChanged,
  setFavoritesSynchronizationStatus,
  toggleLocationStatus,
  toggleMenu,
  updateObject,
  updatePageState,
  userDataUpdated,
  handleSetSearchFieldVisible,
  noteDeleted,
  noteSaved,
  searchPerformed,
  updateKeyboardState,
  fetchFavorites,
  clearSearchResults,
  setIsFavoriteFalse,
  itemFetched,
} from 'src/store/reducers/commons';
import STATUS from 'src/store/fetchStatuses';
import { HAPPENINGS_PAGE_KEY } from 'src/pages/pagesKeys';

const getDefaultState = () => ({ ...getPageCommonDefaultState(HAPPENINGS_PAGE_KEY) });

/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
const _toggleFavorite = (state, action, contextualDataType) => {
  let update = {};
  if (
    action.favListUpdated &&
    action.dataType === contextualDataType &&
    typeof state.id !== 'undefined' &&
    state.id !== null &&
    state.id == action.id
  ) {
    update = {
      isFavorite: isFavorite(state.id, contextualDataType),
    };
  }

  return updateObject(state, {
    favorites: action.favorites,
    ...update,
  });
};

const _fetchHappenings = (state, action) =>
  updateObject(state, {
    happenings: action.happenings,
    campaign: action.campaign,
    inputs: action.inputs,
    isPending: action.status === STATUS.PENDING,
    shouldFetch: false,
    labels: getLabels(),
  });

function _navigate(state, action) {
  if (
    action.pageKey !== HAPPENINGS_PAGE_KEY ||
    (action.options && action.options.id !== state.id)
  ) {
    return updateObject(state, {
      item: null,
      shouldFetch: false,
    });
  }
  return state;
}

function _itemFetched(state, action) {
  let newState = itemFetched(state, action, DATA_TYPE_HAPPENINGS);

  if (newState !== state) {
    // State updated

    if (newState.appointmentRequestStatus.isEnabled) {
      // Check if an appointment request has already been sent
      const originalId = action.item.original_id;
      const appointmentRequestStatus = getRequestStatus(originalId, DATA_TYPE_HAPPENINGS);

      return __updateAppointmentRequestStatus(newState, appointmentRequestStatus);
    }
  }

  if (action.dataType === DATA_TYPE_HAPPENINGS) {
    newState = _getEventContrib(newState);
  }
  if (action.dataType === DATA_TYPE_HAPPENINGS) {
    newState.contactDate = action.contactDate;
    newState.contactDateLabel = action.contactDateLabel;
    newState.isSameUSer = action.isSameUSer;
  }

  return newState;
}

function _itemBeingFetched(state, action) {
  if (action.id === state.id && action.dataType === state.dataType) {
    return updateObject(state, {
      shouldFetch: false,
      isPending: true,
      item: null,
    });
  }
  return state;
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case ACTIVATE_CONTRIBUTIONS_REFRESH:
      return updateObject(state, { activaterefresh: action.activate });

    case ALL_FAVORITES_DELETED:
      return setIsFavoriteFalse(state);

    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state, action, HAPPENINGS_PAGE_KEY);

    case DATA_ASSETS_UPDATED:
      return updateObject(state, { shouldFetch: true });

    case FETCH_FAVORITES:
      return fetchFavorites(state, action);

    case ITEM_BEING_FETCHED:
      return _itemBeingFetched(state, action);

    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);

    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);

    case LANG_CHANGED:
      return langChanged(state, action);

    case PROFILE_CHANGED:
      return profileChanged(state, action, HAPPENINGS_PAGE_KEY);

    case SET_FAVORITES_SYNCHRONIZATION_STATUS:
      return setFavoritesSynchronizationStatus(state, action);

    case NOTE_DELETED:
      return noteDeleted(state, action);

    case NOTE_SAVED:
      return noteSaved(state, action);

    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);

    case SEARCH_PERFORMED:
      return searchPerformed(state, action, HAPPENINGS_PAGE_KEY);

    case SET_SEARCH_FIELD_VISIBLE:
      return handleSetSearchFieldVisible(state, action, HAPPENINGS_PAGE_KEY);

    case TOGGLE_FAVORITE:
      return _toggleFavorite(state, action, DATA_TYPE_HAPPENINGS);

    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);

    case TOGGLE_MENU:
      return toggleMenu(state, action, HAPPENINGS_PAGE_KEY);

    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, HAPPENINGS_PAGE_KEY);

    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, HAPPENINGS_PAGE_KEY);

    case NAVIGATE:
      return _navigate(state, action);

    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    case FETCH_HAPPENINGS_FEED:
      return _fetchHappenings(state, action);

    default:
      return state;
  }
};
