import { DATA_TYPE_PARTICIPANTS } from 'app-customs/config/dataConfig';
import { SEARCH_TAIGA_PAGE_KEY } from 'src/pages/pagesKeys';
import { HAS_NAVIGATED } from 'src/store/actionTypes';

import { taigaSearch } from 'src/store/actions';

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case HAS_NAVIGATED:
        const pageState = getState()[SEARCH_TAIGA_PAGE_KEY];

        if (
          action.pageKey === SEARCH_TAIGA_PAGE_KEY &&
          action.previousPageKey !== SEARCH_TAIGA_PAGE_KEY &&
          pageState.currentSearchFields
        ) {
          dispatch(
            taigaSearch({
              fields: pageState.currentSearchFields,
              dataType: DATA_TYPE_PARTICIPANTS,
              skipOngoingStatus: true,
            })
          );
        }
        break;

      default: // for linter
    }

    return result;
  };
