import { getBindedActions } from 'src/store/bindedActions';
import config from '../../../app-customs/config/config';
import * as KlipsoSsoPersistence from './KlipsoSsoPersistence';

export function loginSSO() {
  let inAppBrowserRef;
  if (global.isCordovaContext)
    inAppBrowserRef = cordova.InAppBrowser.open(config.SSO.SSO_REQUEST_URL, '_blank');
  else inAppBrowserRef = window.open(config.SSO.SSO_REQUEST_URL, config.SSO.SSO_WEB_LINK_TARGET);

  function executeScriptCallBack(params) {
    if (params[0]) {
      inAppBrowserRef.close();
      getBindedActions().setLoginSSOStatus(true, params[0], null);
    }
  }

  inAppBrowserRef.addEventListener('loadstart', () => {
    setTimeout(() => {
      inAppBrowserRef.executeScript(
        {
          code: '(function() { if(window.response){return window.response;} else{return null;} })()',
        },
        executeScriptCallBack
      );
    }, 500);
  });
}

export function onUserLogInSSO(data) {
  if (data) {
    const userData = config.SSO.userData(JSON.parse(data));
    getBindedActions().setLoginStatus(true, userData, null);
    getBindedActions().userDataUpdated(userData);
    KlipsoSsoPersistence.setUserData(userData);
  }
}
