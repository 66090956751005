import { getHomePage } from 'app-customs/config/config';
import config from 'app-customs/config/config';

import { CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { getPlatform, isCordovaContext } from 'src/core/util/browser';
import * as AppStatus from 'src/core/AppStatus';
import { checkProfile, get as getCurrentProfile } from 'src/core/Profile';
import { queuedNavigation, parseCurrentUrl } from 'src/core/navigation/Router';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { shouldAutoStart } from 'src/pages/mobigeo/locationHelper';
import {
  navigate,
  showIntersticiel,
  requestLocationCapabilities,
  showModalPrivacy,
} from 'src/store/actions';

import {
  CONFIG_JSON_LOADED,
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  VALIDATE_MODAL_PRIVACY_AGREEMENT,
} from 'src/store/actionTypes';

import events from 'src/core/events.js';
import { getIsPrivacyModalAccepted } from 'src/components-standalone/modal-privacy-agreement/ModalPrivacyPersistence';

import { checkModalPrivacy } from 'src/index';

let configJsonLoaded = false;
let dataIsReady = false;
let mountCount = 0;

const _START_LOCATION_ON_APP_BOOT_GOOLE_MAP =
  config.MAP &&
  config.MAP.GOOGLE_MAP?.FEATURE_ENABLED &&
  config.MAP.GOOGLE_MAP.START_LOCATION_ON_APP_BOOT;

/**
 * Before the first page can be displayed, these are required:
 *   - ad config loaded (files/project/config.json): because the page to display could contain an ad
 *   - data loaded: because it might be needed to get item 'id' value from 'originalId' query parameter
 */
function bootIfReady(dispatch) {
  if (AppStatus.hasBooted() !== true && configJsonLoaded === true && dataIsReady === true) {
    AppStatus.setBooted(true);

    let routingData;
    if (queuedNavigation.isEmpty() !== true) {
      routingData = queuedNavigation.get();
    } else {
      routingData = parseCurrentUrl();
    }

    if (checkProfile() !== true) {
      dispatch(navigate(CHOOSE_PROFILE_PAGE_KEY, { thenNavigateTo: routingData }));
    } else {
      if (!routingData) {
        routingData = getHomePage(getCurrentProfile());
      }
      dispatch(
        showIntersticiel(true, function () {
          TwoColumnsModeManager.autoEnable(mountCount !== 0);
          dispatch(navigate(routingData.pageKey, routingData.props));
        })
      );
    }
    events.publish('appReady');
    // iOS hack (when navigating, <body> background image is visible)
    document.getElementById('app-root').classList.add('app-ready');
  }
}

function _showModalPrivacyAgreement(dispatch) {
  if (
    isCordovaContext() !== true ||
    config.MODAL_PRIVACY_AGREEMENT.ENABLED_PLATFORM_FEATURE === undefined ||
    getIsPrivacyModalAccepted() ||
    config.MODAL_PRIVACY_AGREEMENT.ENABLED_PLATFORM_FEATURE.includes(getPlatform()) !== true
  ) {
    configJsonLoaded = true;
    bootIfReady(dispatch);

    if (shouldAutoStart() || _START_LOCATION_ON_APP_BOOT_GOOLE_MAP) {
      dispatch(requestLocationCapabilities());
    }
  } else {
    dispatch(showModalPrivacy);
  }
}

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case CONFIG_JSON_LOADED:
        _showModalPrivacyAgreement(dispatch);
        break;

      case VALIDATE_MODAL_PRIVACY_AGREEMENT:
        {
          checkModalPrivacy();
          configJsonLoaded = true;
          bootIfReady(dispatch);

          if (shouldAutoStart() || _START_LOCATION_ON_APP_BOOT_GOOLE_MAP) {
            dispatch(requestLocationCapabilities());
          }
        }
        break;

      case DATA_ASSETS_UPDATED:
        dataIsReady = true;
        bootIfReady(dispatch);
        break;

      case HAS_NAVIGATED:
        const restrictionsTwoColumnsMode =
          config.TWO_COLUMNS_MODE_MANAGER && config.TWO_COLUMNS_MODE_MANAGER.RESTRICTIONS;
        const restrictionPageKey = restrictionsTwoColumnsMode?.find(
          (restr) => restr?.pageKey === action.pageKey && restr?.profile === getCurrentProfile()
        )
          ? true
          : false;
        mountCount++;
        if (mountCount < 2) {
          if (!restrictionPageKey && TwoColumnsModeManager.isEnabled()) {
            TwoColumnsModeManager.showSecondPage(
              action.pageKey,
              action.pageProps,
              getCurrentProfile(),
              // Action to navigate:
              function (pageKey, pageProps) {
                dispatch(navigate(pageKey, pageProps));
              }
            );
          }
        }
        break;

      default:
    }
    return result;
  };
