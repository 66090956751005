import { DISABLE_FAVORITE_ICON } from 'app-customs/config/listConfig';
import {
  DATA_TYPE_PARTICIPANTS,
  DATA_TYPE_SPEAKER_CATEGORIES,
  // CATEGORIES_DATA_TYPE,
  CATEGORIES_MAPPING,
  DATA_TYPE_MEETING_CONTACTS,
} from 'app-customs/config/dataConfig';

import {
  ALL_FAVORITES_DELETED,
  CLEAR_SEARCH_RESULTS,
  CONFIG_JSON_LOADED,
  CORDOVA_PAUSE,
  CORDOVA_RESUME,
  DATA_ASSETS_UPDATED,
  DOCUMENT_VISIBLE,
  HAS_NAVIGATED,
  HIDE_FILTER_DIALOG,
  ITEMS_FETCHED,
  KEYBOARD_TOGGLED,
  LANG_CHANGED,
  LISTS_FETCHED,
  NAVIGATE,
  POLL_CONFIG_LOADED,
  PROFILE_CHANGED,
  SEARCH_PERFORMED,
  SET_SEARCH_FIELD_VISIBLE,
  SHOW_FILTER_DIALOG,
  TOGGLE_FAVORITE,
  TOGGLE_LOCATION_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  USER_DATA_UPDATED,
  WINDOW_RESIZED,
} from 'src/store/actionTypes';

import {
  clearSearchResults,
  allFavoritesDeleted,
  configJsonLoaded,
  dataUpdated,
  getPageCommonDefaultState,
  handleSetSearchFieldVisible,
  langChanged,
  pollConfigLoaded,
  profileChanged,
  searchPerformed,
  setAppVisible,
  toggleFavorite,
  toggleLocationStatus,
  toggleMenu,
  togglePageAfterNavigation,
  updateKeyboardState,
  updateObject,
  updatePageState,
  userDataUpdated,
  windowResized,
} from 'src/store/reducers/commons';

import { parseListItems } from 'src/store/reducers/listCommons';

import { LIST_PAGE_KEY } from 'src/pages/pagesKeys';
import { getUserData } from 'src/core/user-data/UserDataService';

const getDefaultState = () => ({
  ...getPageCommonDefaultState(LIST_PAGE_KEY),
  favorites: {},
  favIconDisabled: DISABLE_FAVORITE_ICON === true,
  isFilterVisible: false,
  userData: getUserData(),
});

function getUsedTables(inputs) {
  const usedTables = [];

  if (!inputs) {
    return usedTables;
  }
  inputs.forEach((input) => {
    usedTables.push(input.dataType);

    // Allow lists to hold both cats and items
    const childrenDataType = CATEGORIES_MAPPING[input.dataType];
    if (childrenDataType) {
      usedTables.push(childrenDataType);
    }
  });

  return usedTables;
}

/**
 * Initial action for list page
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
function _listsFetched(state, action) {
  const { items } = action;
  const firstDataType = Object.keys(items)[0];

  return updateObject(state, {
    inputs: action.inputs,
    status: action.status,
    items: parseListItems(action.status, items, LIST_PAGE_KEY),
    favorites: action.favorites,
    contextualTitle:
      action.inputs[0].dataType === DATA_TYPE_SPEAKER_CATEGORIES
        ? null
        : action.header
        ? null
        : action.contextualTitle,
    header: action.header,
    shouldFetch: false,
    hasShowOnMapButton:
      Array.isArray(items[firstDataType]) &&
      items[firstDataType].length > 0 &&
      state.locateAll === true,
    customStateOnMap: action.customStateOnMap,
  });
}

function _itemsFetched(state, action) {
  const firstDataType = Object.keys(action.items)[0];

  return updateObject(state, {
    inputs: action.inputs,
    status: action.status,
    items: parseListItems(action.status, action.items, LIST_PAGE_KEY),
    favorites: action.favorites,
    contextualTitle: '', // action.header ? null : action.contextualTitle,
    header: action.header,
    shouldFetch: false,
    hasShowOnMapButton:
      Array.isArray(action.items[firstDataType]) &&
      action.items[firstDataType].length > 0 &&
      state.locateAll === true,
    customStateOnMap: false,
  });
}

function _toggleFilterDialog(state, isVisible) {
  return updateObject(state, {
    isFilterVisible: isVisible,
  });
}

function _updatePageState(state, action) {
  return updateObject(updatePageState(state, action, LIST_PAGE_KEY), {
    locateAll: !!action.props.locateAll,
    hasFilter: !!action.props.hasFilter,
    hasGoToSynopticButton: !!action.props.hasGoToSynopticButton,
    filterEnabled: !!action.props.filterEnabled,
    filterCatsCount:
      typeof action.props.filterCatsCount === 'number' ? action.props.filterCatsCount : null,
  });
}

function _navigate(state, action) {
  if (action.pageKey === LIST_PAGE_KEY) {
    const newState = _toggleFilterDialog(state, false);

    const { inputs } = action.options;
    if (
      (Array.isArray(inputs) &&
        inputs.length === 1 &&
        inputs[0].dataType === DATA_TYPE_PARTICIPANTS) ||
      inputs[0].dataType === DATA_TYPE_MEETING_CONTACTS
    ) {
      // Always refresh participants and meeting Contacts data
      newState.shouldFetch = true;
    }

    if (JSON.stringify(inputs) !== JSON.stringify(state.inputs)) {
      newState.items = null;
      newState.shouldFetch = true;
    }

    if (state.isVisible && state.searchResults) {
      newState.searchResults = null;
    }

    if (Object.keys(newState).length > 0) {
      return updateObject(state, newState);
    }
  }
  return state;
}

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case CORDOVA_PAUSE:
      return setAppVisible(state, false);
    case CORDOVA_RESUME:
      return setAppVisible(state, true);
    case DOCUMENT_VISIBLE:
      return setAppVisible(state, action.value);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, LIST_PAGE_KEY);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case ALL_FAVORITES_DELETED:
      return allFavoritesDeleted(state);
    case DATA_ASSETS_UPDATED:
      return dataUpdated(state, action, getUsedTables(state.inputs));
    case KEYBOARD_TOGGLED:
      return updateKeyboardState(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case LISTS_FETCHED:
      return _listsFetched(state, action);
    case ITEMS_FETCHED:
      return _itemsFetched(state, action);
    case NAVIGATE:
      return _navigate(state, action);
    case PROFILE_CHANGED:
      return profileChanged(state, action, LIST_PAGE_KEY, true);
    case SEARCH_PERFORMED:
      return searchPerformed(state, action, LIST_PAGE_KEY);
    case SET_SEARCH_FIELD_VISIBLE:
      return handleSetSearchFieldVisible(state, action, LIST_PAGE_KEY);
    case TOGGLE_FAVORITE:
      return state.inputs ? toggleFavorite(state, action, getUsedTables(state.inputs)) : state;
    case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_MENU:
      return toggleMenu(state, action, LIST_PAGE_KEY);
    case UPDATE_PAGE_STATE:
      return _updatePageState(state, action);
    case WINDOW_RESIZED:
      return windowResized(state, action);
    case CLEAR_SEARCH_RESULTS:
      return clearSearchResults(state, action, LIST_PAGE_KEY);
    case SHOW_FILTER_DIALOG:
      return _toggleFilterDialog(state, true);
    case HIDE_FILTER_DIALOG:
      return _toggleFilterDialog(state, false);
    case USER_DATA_UPDATED:
      return userDataUpdated(state, action);

    default:
      return state;
  }
};
