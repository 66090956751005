import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { HOURS_PAGE_KEY } from 'src/pages/pagesKeys';

import Description from 'src/components/fiche/Description';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import ShareButton from 'src/components/share-button/ShareButton';

import '../common-fiche.scss';
import './HoursPage.scss';

class HoursContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  render() {
    const title = this.props.item.title,
      logoUrl = this.props.item.logo_file_name,
      description = this.props.item.description;

    return (
      <div className="fiche hours content-font content-below-apptoolbar">
        <DetailFixedTitle title={title} hideFav={true} labels={this.props.labels}>
          <ShareButton
            name={title}
            queryString={this.props.queryString}
            description={description}
            image={logoUrl}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={HOURS_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <Description
              logoFileName={logoUrl}
              description={description}
              isLogoFullWidth={this.props.isLogoFullWidth}
            />
          </div>
        </div>
      </div>
    );
  }
}

HoursContent.propTypes = {
  item: PropTypes.object,
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default HoursContent;
