/**
 * Declare pages used by the app
 * Pages not exported here won't be compiled/included in the build
 */

// import aircraftPageConfig        from 'src/pages/aircraft/config';
//import chooseProfilePageConfig from 'src/pages/choose-profile/config';
//import eventPageConfig from 'src/pages/event/config';
//import speakerPageConfig from 'src/pages/speaker/config';
//import paperPageConfig from 'src/pages/paper/config';
//import exhibitorPageConfig from 'src/pages/exhibitor/config';
// import experiencesItemPageConfig from 'src/pages/experience_item/config';
import favoritesPageConfig from 'src/pages/favorites/config';
// import flightsSchedulePageConfig from 'src/pages/flights_schedule/config';
//import homePageConfig from 'src/pages/home/config';
import hoursPageConfig from 'src/pages/hours/config';
import inboxPageConfig from 'src/pages/inbox/config';
//import klipsoLeadsPageConfig from 'src/pages/klipso-leads/config';
import listPageConfig from 'src/pages/list/config';
import listGroupsPageConfig from 'src/pages/list-groups/config';
//import loginPageConfig from 'src/pages/login/config';
import mobigeoPageConfig from 'src/pages/mobigeo/config';
//import mediasPageConfig from 'src/pages/medias/config';
//import mediasFeedPageConfig from 'src/pages/medias-feed/config';
//import newProductPageConfig from 'src/pages/new-product/config';
//import participantPageConfig from 'src/pages/participant/config';
//import contactPageConfig from 'src/pages/contact/config';
//import pmrPageConfig             from 'src/pages/pmr/config';
// import restaurantPageConfig      from 'src/pages/restaurant/config';
//import searchPageConfig from 'src/pages/search/config';
//import searchTaigaPageConfig from 'src/pages/search-taiga/config';
//import servicePageConfig from 'src/pages/service/config';
// import docunitPageConfig         from 'src/pages/docunit/config';
//import synopticAgendaPageConfig from 'src/pages/synoptic-agenda/config';
//import notesPageConfig from 'src/pages/notes/config';
////import userDataPageConfig from 'src/pages/user-data/config';
//import gmapPlacesPageConfig from 'src/pages/gmapplaces/config';
//import liveStreamConfig from 'src/pages/liveStream/config';
//import storesConfig from 'src/pages/stores/config';
//import googleMapPageConfig from 'src/pages/googleMap/config';
//import goldenSummitPageConfig from 'src/pages/golden-summit/config';
//import userProjectPageConfig from 'src/pages/user-project/config';
//import jobOfferPageConfig from 'src/pages/job-offer/config';
//import contestPageConfig from 'src/pages/contest/config';
//import meetingContactsPageConfig from 'src/pages/meetingContacts/config';
//import helpersPageConfig from 'src/pages/helpers/config';
import restaurantPageConfig from 'src/pages/restaurant/config';
import monumentPageConfig from 'src/pages/monument/config';
import happeningPageConfig from 'src/pages/happening/config';
import searchPageConfig from 'src/pages/search/config';
import servicePageConfig from 'src/pages/service/config';
import brandPageConfig from 'src/pages/brand/config';
export default [favoritesPageConfig, hoursPageConfig, inboxPageConfig, listPageConfig, mobigeoPageConfig, restaurantPageConfig, monumentPageConfig, happeningPageConfig, searchPageConfig, servicePageConfig, brandPageConfig];