import { ENV } from 'app-customs/config/config';

import { simpleSort } from 'src/core/util/sortUtil';
import { getBindedActions } from 'src/store/bindedActions';

const LOG_PREF = '[KlipsoLeadsData] ';

export const FIELD_TYPES = {
  EDIT_TEXT: 0,
  TEXT_AREA: 1,
  DATE_TIME: 2,
  NUMERIC: 3,
  BOOLEAN: 4,
  SINGLE_CHOICE: 5,
  MULTI_CHOICE: 6,
  EMAIL: 8,
};

const getParsedLocalStorageValue = (key) => JSON.parse(window.localStorage.getItem(key));

// Disclaimer status
const DISCLAIMER_STATUS_LOCAL_STORAGE_KEY = 'kl-disclaimer-status';

export function setDisclaimerAccepted(value) {
  window.localStorage.setItem(DISCLAIMER_STATUS_LOCAL_STORAGE_KEY, value);
}
export const hasAcceptedDisclaimer = () =>
  getParsedLocalStorageValue(DISCLAIMER_STATUS_LOCAL_STORAGE_KEY) === true;

// Context (licence, username, exhibitor, event, checkpoint id)
const CONTEXT_STORAGE_KEY = 'kl-context';

export function setContext(context) {
  window.localStorage.setItem(CONTEXT_STORAGE_KEY, JSON.stringify(context) || null);
}
export const getContext = () => getParsedLocalStorageValue(CONTEXT_STORAGE_KEY);

// FormFields

const FORM_FIELDS_STORAGE_KEY = 'kl-form-fields';

export function setFormFields(formFields) {
  window.localStorage.setItem(FORM_FIELDS_STORAGE_KEY, JSON.stringify(formFields) || null);
}
export const getFormFields = () => getParsedLocalStorageValue(FORM_FIELDS_STORAGE_KEY);

if (ENV === 'dev') {
  global.getFormFields = getFormFields;
}

export const isBadgeField = (field) => typeof field.CABPosition === 'number';

export function getBadgeFields() {
  // Check form fields
  const formFields = getFormFields();
  if (!formFields || Array.isArray(formFields) !== true || formFields.length === 0) {
    // console.error(LOG_PREF+'Missing or invalid FormFields');
    return null;
  }

  // Filter+check bagde fields
  const badgeFields = formFields
    .filter(isBadgeField)
    .sort((f1, f2) => simpleSort(f1.CABPosition, f2.CABPosition));
  if (badgeFields.length === 0) {
    console.error(
      `${LOG_PREF}Missing badge fields (FormFields items where CABPosition is a number)`
    );
    return null;
  }
  return badgeFields;
}

export function splitFields(formFields) {
  if (!formFields) {
    return {};
  }

  const readOnlyFields = [];
  const editableFields = [];

  formFields.forEach((formField) => {
    if (formField.Nature === 0 || formField.Nature === 1) {
      readOnlyFields.push(formField);
    } else {
      editableFields.push(formField);
    }
  });

  return { readOnlyFields, editableFields };
}

if (ENV === 'dev') {
  global.splitFields = splitFields;
}

// Contacts storage

const CONTACTS_STORAGE_KEY = 'kl-contacts';

export async function saveContacts(_contacts) {
  // Clone object to update reference and trigger proper re-renders
  contacts = Array.isArray(_contacts) ? _contacts.slice(0) : [];

  localStorage.setItem(CONTACTS_STORAGE_KEY, JSON.stringify(contacts));
  getBindedActions().klipsoLeadsContactsUpdated(contacts);
}

function readContacts() {
  return JSON.parse(localStorage.getItem(CONTACTS_STORAGE_KEY)) || [];
}

// Contacts logic

let contacts = readContacts();

export const getContacts = () => contacts;

export const getContact = (code) => contacts.find((contact) => contact.code === code);

/**
 * Add or update a contact
 * @param  {object} contact
 * @param  {boolean} skipSave (optional) using false allows to batch saves and manually 'commit' at the end
 */
export function saveContact(contact, skipSave) {
  const existingIndex = contacts.findIndex((_contact) => _contact.code === contact.code);

  if (existingIndex !== -1) {
    // Update contact
    contacts.splice(existingIndex, 1, contact);
  } else {
    // Push contact
    contacts.push(contact);
  }

  if (!skipSave) {
    saveContacts(contacts);
  }
}
