import { latinise } from 'src/core/util/StringUtil';

const naturalSort = require('javascript-natural-sort');

/**
 * Sort 2 strings
 * (NB: Using natural-sort fixes this: "Boutique 1", "Boutique 10", "Boutique 11", "Boutique 12", "Boutique 2")
 * @param  {string} strA
 * @param  {string} strB
 * @return {number} -1 if strA is before strB  /  1 if strB is before strA  /  0 if strings are the same
 */
export function sortStrings(strA, strB) {
  // Fix sort issue ('États-unis' considered after 'Ukraine')
  const sA = latinise(String(strA).toUpperCase());
  const sB = latinise(String(strB).toUpperCase());

  return [sA, sB].sort(naturalSort).indexOf(sA) === 0 ? -1 : 1;

  // s1.localeCompare(s2, getCurrentLang());
}

/**
 * Simplest sort
 * @param  {*} tsA
 * @param  {*} tsB
 * @return {number}
 */
export function simpleSort(tsA, tsB) {
  if (tsA > tsB) {
    return 1;
  }
  if (tsA < tsB) {
    return -1;
  }
  return 0;
}

/**
 * Simplest sort desc
 * @param  {*} tsA
 * @param  {*} tsB
 * @return {number}
 */
export function simpleSortDesc(tsA, tsB) {
  if (tsA > tsB) {
    return -1;
  }
  if (tsA < tsB) {
    return 1;
  }
  return 0;
}

/**
 * Sort by stringified hour, e.g sort '17h15' and '08h30'
 * @param  {string} str1
 * @param  {string} str2
 * @return {number}
 */
export function sortByStringifiedHour(str1, str2) {
  const hourA = parseInt(str1.slice(0, 2), 10);
  const hourB = parseInt(str2.slice(0, 2), 10);

  if (hourA > hourB) {
    return 1;
  }
  if (hourA < hourB) {
    return -1;
  }
  // if hour are equal, sort by minutes
  const minA = parseInt(str1.slice(3, 5), 10);
  const minB = parseInt(str2.slice(3, 5), 10);

  if (minA > minB) {
    return 1;
  }
  if (minA < minB) {
    return -1;
  }
  return 0;
}
