import { SEND_APPOINTMENT_REQUEST } from 'src/store/actionTypes';
import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import { get as getLabels } from 'src/core/Lang';

import * as UserDataService from 'src/core/user-data/UserDataService';

import { appointmentRequestSent, navigate, showNotification } from 'src/store/actions';
import { sendRequest } from './AppointmentRequestService';

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SEND_APPOINTMENT_REQUEST:
        const profile = UserDataService.getUserData();

        if (UserDataService.isUserDataEnoughSet(profile)) {
          // Profile OK, send request
          sendRequest(action.dataOriginalId, action.dataType, action.dataId, profile);
          dispatch(appointmentRequestSent(action.dataId, action.dataType));
        } else {
          // Modale indicating to set missing `profile` value
          dispatch(
            showNotification({
              message: getLabels().appointment.setProfileFirst,
            })
          );

          // Show `profile` modale
          dispatch(navigate(USER_DATA_PAGE_KEY));
        }
        break;

      default:
    }

    return result;
  };
