import {
  KLIPSOLEADS_SET_DISCLAIMER_STATUS,
  KLIPSOLEADS_REGISTER_SUCCESS,
} from 'src/store/actionTypes';

import { setDisclaimerAccepted, setContext, setFormFields } from './KlipsoLeadsData';

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case KLIPSOLEADS_SET_DISCLAIMER_STATUS:
        setDisclaimerAccepted(action.value);
        break;

      case KLIPSOLEADS_REGISTER_SUCCESS:
        setContext(action.context);
        setFormFields(action.formFields);
        break;

      default: // for linter
    }

    return result;
  };
