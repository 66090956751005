import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from 'src/components/dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import ChooseLangInline from 'src/components/choose-lang-inline/ChooseLangInline';

import * as actions from 'src/store/actions';

import './ChooseLangDialog.scss';

export const COMPONENT_KEY = 'ChooseLangDialog';
export const CONTAINER_DOM_ID = 'choose-lang-dialog-container';

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
function ChooseLangDialog({ supportedLangs, isOpen, labels, actions }) {
  const currentLangId = labels.id;

  /**
   * Actions when user has clicked a flag
   * @param  {string} lang
   */
  function handleFlagSelected(lang) {
    if (lang !== currentLangId) {
      actions.setLanguage(lang);
    }
    actions.hideChooseLangDialog();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        actions.hideChooseLangDialog();
      }} // important, to update `open` value in all cases
    >
      <DialogTitle className="modal-title-lang ">
        {labels.common.chooseLang.toUpperCase()}
      </DialogTitle>

      <div className="generic-modal-content-lang">
        <div id="cl-langs">
          <ChooseLangInline labels={labels} actions={actions} onFlagClick={handleFlagSelected} />
        </div>

        <div className="generic-btn-container">
          <div className="generic-btn-cancel" onClick={actions.hideChooseLangDialog}>
            {labels.common.cancel}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

ChooseLangDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLangDialog);
