import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './Loader.scss';

function Loader({ className, theme, labels }) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const timer = window.setTimeout(setVisible, 10, true);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const classNames = [
    'simple-loader',
    theme || '',
    className || '',
    isVisible ? 'simple-loader-visible' : '',
  ];

  return (
    <div className={classNames.join(' ')}>
      <GenericIcon
        layout="div"
        className="simple-loader-icon sync-icon sync-icon-loader rotate-center"
      />
      <div>{labels.common.loading}</div>
    </div>
  );
}

Loader.propTypes = {
  labels: PropTypes.object.isRequired,
  theme: PropTypes.string,
  className: PropTypes.string,
};

export default Loader;
