import Pages from 'src/pages/Pages';
import GenericItemPage from './GenericItemPage';

let keysOfPagesUsingGenericItem;

export function initKeysOfPagesUsingGenericItem() {
  keysOfPagesUsingGenericItem = Object.keys(Pages).filter(
    (pageKey) => Pages[pageKey].component === GenericItemPage
  );
}

export const getKeysOfPagesUsingGenericItem = () => keysOfPagesUsingGenericItem;
