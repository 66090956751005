import { io } from 'socket.io-client';

import config from 'app-customs/config/config';
import { getBindedActions } from 'src/store/bindedActions';

const LOG_PREF = '[RealTimeService] ';

let socket;
const registeredEvents = [];

/**
 * Start WebSocket connection
 * @param {string} query
 */
export function connect(query) {
  // Close any existing connection
  disconnect();

  socket = io(config.REAL_TIME.URL + (query ? `/?${query}` : ''));

  socket.on('connect', () => {
    console.log(`${LOG_PREF}WS connection opened`);
    // Ui.setConnected();
    getBindedActions().realTimeConnected();
  });

  socket.on('disconnect', () => {
    console.log(`${LOG_PREF}WS connection closed`);
    // Ui.setDisconnected();
    getBindedActions().realTimeDisconnected();
  });

  // Register custom events
  registeredEvents.forEach((handlerData) => {
    _registerEvent(handlerData.eventName, handlerData.callback);
  });
}

/**
 * Allows random modules to listen to real-time events
 */
export function registerEvent(eventName, callback) {
  registeredEvents.push({
    eventName,
    callback,
  });

  _registerEvent(eventName, callback);
}

function _registerEvent(eventName, callback) {
  if (socket) {
    socket.on(eventName, (data) => {
      let parsedData;
      try {
        parsedData = JSON.parse(data);
      } catch (e) {
        console.warn(`${LOG_PREF}data is not parseable`, data);
      }
      callback(parsedData || data);
    });
  }
}

/**
 * Terminate WebSocket connection
 */
export function disconnect() {
  if (socket) {
    socket.close();
  }
}

/**
 * Broadcast to server
 * @param {string} eventName
 * @param {*} data
 */
export function emit(eventName, data) {
  socket.emit(eventName, data);
}
