import fetchHelper from 'src/core/util/FetchHelper';
import config from '../../../app-customs/config/config';
import { getBindedActions } from 'src/store/bindedActions';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang';
import showPromptModal from 'src/core/util/showPromptModal';
import * as pdfSecuredPersistence from './pdfSecuredPersistence';
import { openUrl } from 'src/core/util/JsTools';
import { isAndroid } from 'src/core/util/browser';
import showPdf from 'src/core/pdf/pdfViewerAndroid';
import { saveAndOpenBlobAsFile } from 'src/core/cordova/fileSystem';

const LOG_PREF = '[PdfSecured] ';

export function performSendRequest(payload, sender) {
  const public_flag = payload.public_flag;
  const _Url = public_flag
    ? config.PUBLIC_PDF.PUBLIC_PDF_REQUEST_URL
    : config.SECURED_PDF.SECURED_PDF_REQUEST_URL;
  const filename = payload.namePdf.concat('.pdf');
  const urlPdf = _Url + '/' + filename;
  const opts = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  !public_flag && (opts.headers = { ...opts.headers, Authorization: `Bearer ${payload.key}` });
  console.log(`${LOG_PREF} opts: `, JSON.stringify(opts));
  fetchPdf(urlPdf, opts, payload, sender);
}
export const showNoSecuredPdf = (urlPdf, payload) => {
  if (!urlPdf) {
    console.error(`${LOG_PREF} no url pdf`);
    return;
  }
  const namePdf = urlPdf.split('/').slice(-1);
  fetchPdf(
    urlPdf,
    {
      method: 'GET',
    },
    { ...payload, namePdf: unescape(namePdf), public_flag: true }
  );
};
export const fetchPdf = (urlPdf, opts, payload, sender) => {
  const { reject: rej, resolve: res } = sender || {};
  fetchHelper(
    urlPdf,
    opts,
    false, // parse response as json
    function onSuccess(data) {
      console.log(`${LOG_PREF} onSuccess: `, data);
      if (data && data.error) {
        rej && rej({ message: 'error :' + getLabels().pdfSecured.erreurMessage });
        // getBindedActions().showNotification({
        //   message: getLabels().pdfSecured.erreurMessage,
        //   level: NotificationLevels.ERROR,
        // });
      } else if (data) {
        if (typeof res === 'function') {
          res && res('success');
        }
        data.blob().then((res) => {
          const _urlPdf = window.URL.createObjectURL(res);
          console.log(`${LOG_PREF} url Pdf: `, _urlPdf);
          if (!payload.public_flag) {
            pdfSecuredPersistence.setPdfSecuredKey(payload.key);
          }
          if (isAndroid()) {
            showPdf(urlPdf, payload.namePdf, {
              headerColor: '#000000',
              showScroll: true,
              showShareButton: false,
              showCloseButton: true,
              swipeHorizontal: false,
              authorizationKey: payload.key,
            });
            // const folderpath = cordova.file.cacheDirectory;
            // const contentType = 'application/pdf';
            // const filename = payload.namePdf.concat('.pdf');
            // saveAndOpenBlobAsFile(folderpath, filename, res, contentType);
          } else if (openUrl(_urlPdf, null, '_blank', true)) {
            getBindedActions().navigate(_urlPdf);
          }
        });
      }
    },
    function onFailure(error, reason) {
      console.error(`${LOG_PREF} onFailure::${error}`, `Message Error:${reason} `);
      if (typeof rej === 'function') {
        if (error.includes('401')) {
          rej({ message: getLabels().pdfSecured.error401, error });
        }
        if (error.includes('404')) {
          rej({ message: getLabels().pdfSecured.error404, error });
        }
        if (error.includes('503')) {
          rej({ message: getLabels().pdfSecured.error503, error });
          getBindedActions().showNotification({
            message: getLabels().pdfSecured.serverError,
            level: NotificationLevels.ERROR,
          });
        }
      }
      if (pdfSecuredPersistence.getPdfSecuredKey(payload.key)) {
        if (error.includes('401')) {
          pdfSecuredPersistence.removePdfSecuredKey(payload.key);
          showPromptModal({
            text: getLabels().pdfSecured.titleCodeRequired,
            yesBtnLabel: getLabels().common.confirm,
            noBtnLabel: getLabels().common.cancel,
            preConfirm: (value) => {
              return new Promise(function (resolve, reject) {
                performSendRequest({ ...payload, key: value }, { resolve, reject });
              });
            },
            validateCb: async (input) => {},
            noCb: global.noop,
          });
        } else {
          getBindedActions().showNotification({
            message: getLabels().pdfSecured.error404,
            level: NotificationLevels.ERROR,
          });
        }
      }
    },
    false, // showModalOnError
    false,
    true
  );
};

function getNamePdf(item) {
  const ref = item?.references?.categories?.[0]?.title,
    id = ref && ref.split('/')[0],
    psX = ref && ref.split('/')[1]?.split(' ')[0],
    code = item.lump && item.lump?.code;
  return `${id}_-_Full_Papers_${psX}_${code}_2022`;
}

export function checkCodePdf(paperItem) {
  const namePdf = getNamePdf(paperItem);
  if (pdfSecuredPersistence.getPdfSecuredKey()) {
    performSendRequest({ namePdf: namePdf, key: pdfSecuredPersistence.getPdfSecuredKey() });
  } else {
    showPromptModal({
      text: getLabels().pdfSecured.titleCodeRequired,
      yesBtnLabel: getLabels().common.confirm,
      noBtnLabel: getLabels().common.cancel,
      preConfirm: (value) => {
        return new Promise(function (resolve, reject) {
          if (value === '') {
            reject({ message: getLabels().pdfSecured.errorEmpty });
          }
          performSendRequest({ namePdf: namePdf, key: value }, { resolve, reject });
        });
      },
      validateCb: async (input) => {},
      noCb: global.noop,
    });
  }
}
