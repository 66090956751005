import {
  SET_LOGIN_STATUS,
  PERFORM_LOGIN,
  // UPDATE_AUTH_TOKEN
} from 'src/store/actionTypes';

// import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
// import { navigate } from 'src/store/actions';

import { login, onUserLogIn } from './LoginService';

let isLoggedIn = false;

export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const result = next(action);

    switch (action.type) {
      case SET_LOGIN_STATUS:
        if (isLoggedIn !== true && action.loggedIn) {
          onUserLogIn();
          // dispatch(navigate(USER_DATA_PAGE_KEY));
        }
        isLoggedIn = action.loggedIn;
        break;

      case PERFORM_LOGIN:
        login(action.username, action.password);
        break;

      default: // for linter
    }

    return result;
  };
