/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */

import config from 'app-customs/config/config';
import {
// AIRCRAFT_PAGE_KEY,
EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, LIST_PAGE_KEY, HOME_PAGE_KEY,
// NEWPRODUCT_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
SERVICE_PAGE_KEY
// EXPERIENCE_ITEM_PAGE_KEY,
// DOCUNIT_PAGE_KEY
} from 'src/pages/pagesKeys.js';
import {
// DATA_TYPE_ANIMATIONS,
// DATA_TYPE_DOCUNITS,
DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_BRAND_CATEGORIES
// DATA_TYPE_AIRCRAFT_CATEGORIES,
// DATA_TYPE_NEWPRODUCT_CATEGORIES
} from 'app-customs/config/dataConfig';
import { get as getProfile } from 'src/core/Profile';
const {
  DEFAULT_PROFILE,
  WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE,
  WEBAPP_MAP
} = require('./profiles');

// Render state of feature that depends on profile
function FEATURE_PROFILE(profile) {
  let FEATURE_ACTIV;
  switch (profile) {
    case DEFAULT_PROFILE:
      FEATURE_ACTIV = true;
      break;

    /* case WEBAPP_EXH_PROFILE:
      FEATURE_ACTIV = false;
      break;
     case WEBAPP_EVT_PROFILE:
      FEATURE_ACTIV = false;
      break;
     case WEBAPP_MAP:
      FEATURE_ACTIV = false;
      break; */

    default:
      FEATURE_ACTIV = true;
      break;
  }
  return FEATURE_ACTIV;
}
export const FEATURE_ACTIVATED = FEATURE_PROFILE(getProfile());

/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */

export const MIN_WIDTH = 800;
export const AREA = {
  right: 'right',
  left: 'left'
};

/**
 * Default area for all pages
 */
export const TWO_COLUMNS_DEFAULT = AREA.left;

/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */
export const PAGES_SETUP = {
  [config.MAP.DEFAULT_MAP_PAGE_KEY]: AREA.right
  // + fiches (exh, event, service, etc) ?
};

/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */
export const getArea = pageKey => PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;

/**
 * Toolbar is displayed on a single area. Define here on which one
 */
export const TOOLBAR_AREA = AREA.left;

/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */
export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  const displayedPageArea = getArea(displayedPageKey);

  // If displayed page is on left side, then display Map on the right side
  if (displayedPageArea === AREA.left) {
    return config.getHomePage(profile);
  } else {
    /* switch (displayedPageKey) {
      case EVENT_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: { locateAll: false, inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }] },
        };
       case EXHIBITOR_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: { locateAll: false, inputs: [{ dataType: DATA_TYPE_EXHIBITOR_CATEGORIES }] },
        };
       // case AIRCRAFT_PAGE_KEY:
      //     return {
      //         pageKey: LIST_PAGE_KEY,
      //         props  : { inputs: [{ dataType: DATA_TYPE_AIRCRAFT_CATEGORIES }]},
      //     };
       // case NEWPRODUCT_PAGE_KEY:
      //     return {
      //         pageKey: LIST_PAGE_KEY,
      //         props  : { inputs: [{ dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES }]},
      //     };
       // case EXPERIENCE_ITEM_PAGE_KEY:
      //     return {
      //         pageKey: LIST_PAGE_KEY,
      //         props  : { inputs: [{ dataType: DATA_TYPE_ANIMATIONS }]},
      //     };
       // case DOCUNIT_PAGE_KEY:
      //     return {
      //         pageKey: LIST_PAGE_KEY,
      //         props  : { inputs: [{ dataType: DATA_TYPE_DOCUNITS }]},
      //     }
       // case PAPER_PAGE_KEY:
      //     return {
      //         pageKey: LIST_PAGE_KEY,
      //         props  : { inputs: [{ dataType: DATA_TYPE_PAPER_CATEGORIES }]},
      //     };
       case SERVICE_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: { locateAll: false, inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }] },
        };
       case config.MAP.DEFAULT_MAP_PAGE_KEY:
        return config.getHomePage(profile);
    } */
    return {
      pageKey: LIST_PAGE_KEY,
      props: {
        locateAll: false,
        inputs: [{
          dataType: DATA_TYPE_BRAND_CATEGORIES
        }]
      }
    };
  }
}

/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */
export const AUTOSHOW_POI_ON_MAP = {
  /* //    [AIRCRAFT_PAGE_KEY]  : true,
  [EVENT_PAGE_KEY]: true,
  [EXHIBITOR_PAGE_KEY]: true,
  // // [RESTAURANT_PAGE_KEY]: true,
  [SERVICE_PAGE_KEY]: true, */
};