const LOCALSTORAGE_PDF_KEY = 'PdfCheckedKey';

/**
 * IS key checked to open pdf  getter
 * @return {Boolean}
 */
export function getPdfSecuredKey() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_PDF_KEY));

  return data || null;
}

/**
 * IS key checked to open pdf  setter
 * @param {Boolean}
 */
export function setPdfSecuredKey(value) {
  window.localStorage.setItem(LOCALSTORAGE_PDF_KEY, JSON.stringify(value));
}

export function removePdfSecuredKey() {
  window.localStorage.removeItem(LOCALSTORAGE_PDF_KEY);
}
